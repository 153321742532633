import { observable, toJS, reaction } from "mobx";
import { EventStore, useEventStore } from "./eventStore";
import { filter, forEach } from "../utils/lists";

export interface IWeeklyStat {
  name: string
  current: number
  goal: number
}

export class StatStore {
  private eventStore: EventStore = useEventStore()

  @observable
  public dailyScore: number = 0

  @observable
  public weeklyStats: IWeeklyStat[] = []

  private reactions: (() => void)[] = []

  private firebaseRefs: firebase.database.Reference[] = []

  constructor() {
    this.weeklyStats = [
      {
        name: 'exercise',
        current: 0,
        goal: 5
      },
      {
        name: 'meditation',
        current: 0,
        goal: 5
      },
      {
        name: 'fft',
        current: 0,
        goal: 1
      },
      {
        name: 'planning',
        current: 0,
        goal: 5
      }
    ]
  }

  public async addReactions() {
    this.reactions.push(reaction(() => toJS(this.eventStore.events), (events) => {
      let dailyScore = 0;
      let calories = 0;
      forEach(events, (event: any) => {
        if (event.type === 'habit') {
          switch (event.name) {
            case 'exercise':
              dailyScore += 2;
              break;
            case 'meditation':
              dailyScore += 1;
              break;
            default:
              dailyScore += 0.5;
              break;
          }
        }

        if (event.name.indexOf('pomodoro') !== -1) {
          dailyScore += event.value * 0.5;
        }

        if (event.type === 'food') {
          calories += event.value;
        }
      });

      if (calories > 2200) {
        dailyScore -= 6;
      }

      this.dailyScore = dailyScore;
    }));

    this.reactions.push(reaction(() => toJS(this.eventStore.weeklyEvents), (events) => {
      this.weeklyStats.forEach((stat) => {
        stat.current = filter(this.eventStore.weeklyEvents, (event: any) => {
          return event.type === 'habit' && event.name === stat.name;
        }).length;
      });
    }));
  }

  public removeReactions() {
    this.reactions && this.reactions.forEach(dispose => dispose());
    this.firebaseRefs && this.firebaseRefs.forEach(ref => ref.off());
  }
}

let statStore: StatStore;
export const useStatStore = () => {
  if (!statStore) {
    statStore = new StatStore();
  }
  
  return statStore;
}
