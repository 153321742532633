
export const forEach = (objectList: any, fn: any) => {
  for (var member in objectList) {
    fn(objectList[member], member);
  }
}

export const map = (objectList: any, fn: any) => {
  const result = []
  for (var member in objectList) {
    result.push(fn(objectList[member], member));
  }
  return result;
}

export const filter = (objectList: any, fn: any) => {
  const newList: any = [];
  for (var member in objectList) {
    if (fn(objectList[member], member)) {
      newList.push(objectList[member]);
    }
  }
  return newList;
}