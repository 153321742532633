import { useFirebase } from "../services/FirebaseService";
import { useUserStore } from "./userStore";
import { action, runInAction, observable } from "mobx";
import { format } from "../utils/dates";
import { filter } from "../utils/lists";

export interface GoalEntity {
  id?: string;
  title: string;
  motivation: string;
  tasks: never[];
  progress: string;
  deadlineAt: string;
  description: string;
  createdAt: string;
  completedAt: Date | null;
  isArchived: boolean;
}

export class GoalStore {
  private reactions: (() => void)[] = [];
  private firebaseRefs: firebase.database.Reference[] = [];
  private goalRef: firebase.database.Reference | null = null;

  @observable
  public goals: GoalEntity[] = []

  public addReactions() {
    this.retrieveGoals();
  }

  public removeReactions() {
    this.reactions && this.reactions.forEach(dispose => dispose());
    this.firebaseRefs && this.firebaseRefs.forEach(ref => ref.off());
  }

  @action
  public async deleteGoal(goalId: string) {
    if (this.goalRef === null) {
      throw new Error("Invalid arguments")
    }

    await this.goalRef.child(goalId).remove();
  }

  @action
  public async createGoal(goal: any) {
    if (this.goalRef === null) {
      throw new Error("this.goalRef is null")
    }

    const payload = JSON.parse(JSON.stringify(goal));
    const id = this.goalRef.push().key as string;

    payload['id'] = id;
    payload['createdAt'] = format(new Date());
    payload['deadlineAt'] = format(goal.deadlineAt)
    payload['isArchived'] = false;

    await this.goalRef.child(id).set(payload);
  }

  @action
  public async updateGoal(goalId: string, object: any) {
    if (!this.goalRef || !goalId) {
      throw new Error("Invalid arguments");
    }

    await this.goalRef.child(goalId).update(object);
  }

  private retrieveGoals() {
    const database = useFirebase().database();
    const uid = useUserStore().userId;
    const ref = this.goalRef = database.ref(`users/${uid}/goals`)

    this.firebaseRefs.push(ref);

    const query = ref.orderByChild('isArchived').equalTo(false);

    query.on('value', (snapshot: any) => {
      runInAction(() => {
        const value = snapshot.val() || {};
        this.goals = filter(value, (goal: any, id: string) => {
          goal['id'] = id;
          return true;
        });
      });
    });

    query.on('child_removed', (snapshot: any) => {
      const child = snapshot.val();

      this.goals = filter(this.goals, (goal: any) => goal.id !== child.id);
    });
  }
}

let goalStore: GoalStore;
export const useGoalStore = () => {
  if (!goalStore) {
    goalStore = new GoalStore();
  }
  
  return goalStore;
}

