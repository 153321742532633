import { observable, action } from 'mobx';
import { addDays } from '../utils/dates';

class DateStore {
  @observable
  public activeDate: Date

  constructor() {
    this.activeDate = new Date();
  }

  @action
  public modifyActiveDate(offset: number) {
    this.activeDate = addDays(this.activeDate, offset);
  }
}

let dateStore: DateStore;
export const useDateStore = (): DateStore => {
  if (!dateStore) {
    dateStore = new DateStore();
  }
  
  return dateStore;
};

export default DateStore;

