import * as React from 'react'
import './Icon.css'

export interface IIconProps {
  id: string
  title?: string
  width?: number | string
  height?: number | string
}

const icons: any = {
  Burger: {
    width: 20,
    height: 9,
    content: <path d="M0 0h20v1H0zM10 8h10v1H10zM0 4h20v1H0z" />
  },
  Checkmark: {
    width: 40,
    height: 40,
    content: (
      <g>
        <line y2="33.53972" x2="17.196262" y1="18.96028" x1="6.915888" />
        <line y2="33.561348" x2="16.761412" y1="3.654804" x1="34.331506" />
      </g>
    )
  },
  Hourglass: {
    width: 40,
    height: 40,
    content: (
      <g>
        <line y2="73.4375" x2="14.5" y1="7.4375" x1="47.5" />
        <line y2="1.4375" x2="15.5" y1="74.4375" x1="52.5" />
        <line y2="1.4375" x2="15.5" y1="7.4375" x1="47.5" />
        <line y2="74.4375" x2="15.5" y1="75.4375" x1="52.5" />
      </g>
    )
  }
}

const Icon: React.FC<IIconProps> = ({ id, title, width, height }) => {
  const icon = icons[id]
  if (!icon) {
    return null
  }

  width = width || icon.width
  height = height || icon.height

  return (
    <div className="icon" aria-label={title} style={{height, width}}>
      <svg
        className="svg"
        focusable="false"
        aria-hidden="true"
        width={width}
        height={height}
        viewBox={`0 0 ${icon.width} ${icon.height}`}
      >
        {icon.content}
      </svg>
    </div>
  )
}

export default Icon
