import React from 'react';
import './Dashboard.css'
import Habits from '../../containers/Habits';
import Statistics from '../../containers/Statistics';
import { observer } from 'mobx-react';
import { StatStore, useStatStore } from '../../stores/statStore';
import Trackers from '../../containers/Trackers';
import { format } from '../../utils/dates';
import { useEventStore, EventStore } from '../../stores/eventStore';
import Quote from '../../components/dashboard/Quote';
import DateStore, { useDateStore } from '../../stores/dateStore';
import Tasks from '../../containers/Tasks';
import { TaskStore, useTaskStore } from '../../stores/taskStore';

export interface IProps {

}

export interface IState {
  score: number
}

@observer
class Dashboard extends React.Component<IProps, IState> {

  private statStore: StatStore = useStatStore();

  private eventStore: EventStore = useEventStore();

  private dateStore: DateStore = useDateStore();

  private taskStore: TaskStore = useTaskStore();

  public componentDidMount() {
    this.statStore.addReactions()
    this.eventStore.addReactions()
    this.taskStore.addReactions()
  }
  
  public componentWillUnmount() {
    this.statStore.removeReactions();
    this.eventStore.removeReactions();
    this.taskStore.removeReactions()
  }

  public modifyActiveDate(offset: number, event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    this.dateStore.modifyActiveDate(offset);
  }

  public render() {
    const { dailyScore: score } = this.statStore;

    return (
      <div className="Dashboard">
        <div className="Date">
          <button className="Date__modify" onClick={this.modifyActiveDate.bind(this, -1)}>&lt;</button>
          <span className="Date__date">{format(this.dateStore.activeDate)}</span>
          <button className="Date__modify" onClick={this.modifyActiveDate.bind(this, 1)}>&gt;</button>
        </div>

        {/* Score */}
        <div className="Dashboard__score">
          <span className="Dashboard__item">{score.toFixed(2)}</span><br />
          <span className="Dashboard__description">Daily Score</span>
        </div>

        {/* Quote */}
        <Quote />

        {/* Trackers */}
        <Trackers />

        {/* Habits */}
        <Habits />

        {/* Tasks */}
        <Tasks />

        {/* Reminders (low priority) */}
        <div className="Reminders">Reminders</div>

        {/* Analytics/Goals */}
        <Statistics />

        {/* Focus */}
        <div className="Focus">
          <p><strong>Focus</strong></p>
          <ul>
            <li>Meditaiton</li>
            <li>Exercise</li>
            <li>Food</li>
          </ul>
        </div>

        {/* Daily Checkin (not really relevant atm) */}

        {/* Activities */}
        <div className="Activities">
          <p><strong>Activities</strong></p>
          <pre>{JSON.stringify(this.eventStore.events, null, 1)}</pre>
        </div>
      </div>
    );
  }
}

export default Dashboard;
