import { useFirebase } from "../services/FirebaseService";
import { useUserStore } from "./userStore";
import { action, runInAction, observable } from "mobx";
import { format } from "../utils/dates";
import { filter } from "../utils/lists";

export interface TaskEntity {
  id?: string;
  title: string;
  createdAt: Date;
  isComplete: boolean;
}

export class TaskStore {
  private reactions: (() => void)[] = [];
  private firebaseRefs: firebase.database.Reference[] = [];
  private taskRef: firebase.database.Reference | null = null;

  @observable
  public tasks: TaskEntity[] = []

  @observable
  public isLoading: boolean = false

  public addReactions() {
    this.retrieveTasks();
  }

  public removeReactions() {
    this.reactions && this.reactions.forEach(dispose => dispose());
    this.firebaseRefs && this.firebaseRefs.forEach(ref => ref.off());
  }

  @action
  public async deleteTask(taskId: string) {
    if (this.taskRef === null) {
      throw new Error("Invalid arguments")
    }

    this.isLoading = true
    await this.taskRef.child(taskId).remove();
    this.isLoading = false
  }

  @action
  public async createTask(task: any) {
    if (this.taskRef === null) {
      throw new Error("this.goalRef is null")
    }

    this.isLoading = true
    const payload = JSON.parse(JSON.stringify(task));
    const id = this.taskRef.push().key as string;

    payload['id'] = id;
    payload['createdAt'] = format(new Date());
    payload['isCompleted'] = false;

    await this.taskRef.child(id).set(payload);
    this.isLoading = false
  }

  @action
  public async updateTask(taskId: string, object: any) {
    if (!this.taskRef || !taskId) {
      throw new Error("Invalid arguments");
    }

    this.isLoading = true
    await this.taskRef.child(taskId).update(object);
    this.isLoading = false
  }

  private retrieveTasks() {
    const database = useFirebase().database();
    const uid = useUserStore().userId;
    const ref = this.taskRef = database.ref(`users/${uid}/tasks`)

    this.firebaseRefs.push(ref);

    const query = ref

    this.isLoading = true
    query.on('value', (snapshot: any) => {
      runInAction(() => {
        const value = snapshot.val() || {};
        this.tasks = filter(value, (task: any, id: string) => {
          task['id'] = id;
          return true;
        });
        this.isLoading = false
      });
    });

    query.on('child_removed', (snapshot: any) => {
      const child = snapshot.val();

      this.tasks = filter(this.tasks, (task: any) => task.id !== child.id);
    });
  }
}

let taskStore: TaskStore;
export const useTaskStore = () => {
  if (!taskStore) {
    taskStore = new TaskStore();
  }
  
  return taskStore;
}


