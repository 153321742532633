import { observable, action } from 'mobx';

class UIStore {
  @observable
  public isMenuActive: boolean = false;

  @action
  public toggleMenu() {
    this.isMenuActive = !this.isMenuActive;
  }
}

let uiStore: UIStore;
export const useUIStore = (): UIStore => {
  if (!uiStore) {
    uiStore = new UIStore();
  }
  
  return uiStore;
};

export default UIStore;


