import moment from 'moment';

export const format = (input: Date, format?: string) => {
  const pattern = format ? format : getDefaultDateFormat();
  return moment(input).format(pattern);
}

export const addDays = (input: Date, amount: number) => {
  return moment(input).add(amount, 'days').toDate();
}

export const getDefaultDateFormat = () => {
  return 'YYYY-MM-DD';
}