import React from 'react';
import './Habits.css'
import { observable, action } from 'mobx'
import { observer } from 'mobx-react';
import { EventStore, useEventStore } from '../../stores/eventStore';
import Habit from '../../components/habits/Habit';

export interface IProps {

}

@observer
class Habits extends React.Component<IProps> {
  private eventStore: EventStore = useEventStore();

  @observable
  private habits = [
    // {
    //   title: 'Meditation',
    //   name: 'meditation',
    //   isDone: false,
    //   weekdays: []
    // },
    {
      title: 'Drugs',
      name: 'drugs',
      isDone: false,
      weekdays: []
    },
    // {
    //   title: 'Exercise',
    //   name: 'exercise',
    //   isDone: false,
    //   weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    // },
    {
      title: 'Daily Planning',
      name: 'planning',
      isDone: false,
      weekdays: []
    },
    // {
    //   title: 'Read News',
    //   name: 'news',
    //   isDone: false,
    //   weekdays: []
    // },
    // {
    //   title: 'Comedy',
    //   name: 'comedy',
    //   isDone: false,
    //   weekdays: []
    // },
    // {
    //   title: '3 Stories',
    //   name: 'stories',
    //   isDone: false,
    //   weekdays: []
    // },
    // {
    //   title: 'FFT',
    //   name: 'fft',
    //   isDone: false,
    //   weekdays: ['Monday']
    // },
  ]

  @action
  private updateStatus(habit: any) {
    habit.isDone = !habit.isDone;

    const existing = this.eventStore.getEvents('habit', habit.name);
    if (existing.length) {
      existing.forEach((event) => this.eventStore.removeEvent(event.id));
      return;
    }

    this.eventStore.createEvent({
      type: 'habit',
      name: habit.name,
      value: 1
    });
  }

  public render() {
    return (
      <div className="Habits">
        <div className="Habits__list">
          {this.habits.map((habit) => (
            <Habit
              key={habit.name}
              title={habit.title}
              name={habit.name}
              weekdays={habit.weekdays}
              onClick={this.updateStatus.bind(this, habit)}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default Habits;
