import React from "react";
import './Statistics.css'
import { StatStore, useStatStore } from "../../stores/statStore";
import { observer } from "mobx-react";
import ProgressBar from "../../components/statistics/ProgressBar/ProgressBar";

export interface IProps {

}

@observer
class Statistics extends React.Component<IProps> {
  private statStore: StatStore = useStatStore();

  public render() {
    const stats = this.statStore.weeklyStats

    return (
      <div className="Statistics">
        <strong>Weekly Metrics</strong>
        {stats.map((stat, key) => (
          <div className="Statistics__metric" key={key}>
            <ProgressBar
              label={stat.name}
              now={stat.current}
              total={stat.goal}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default Statistics
