import React from 'react';
import './Trackers.css'
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';

interface IProps {

}

@observer
class Trackers extends React.Component<IProps> {
  private trackers: any[] = [];

  public componentWillMount() {
    this.trackers = [
      {
        title: 'Pomodoro',
        link: '/trackers/pomodoro'
      },
      {
        title: 'Food',
        link: '/trackers/food'
      }
    ]
  }

  public render() {
    return (
      <div className="Trackers">
        {this.trackers.map((tracker, key) => (
          <NavLink className="Tracker" key={key} to={tracker.link}>
            {tracker.title}
          </NavLink>
        ))}
      </div>
    );
  }
}

export default Trackers;
