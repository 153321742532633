import React from 'react'
import './Quote.css'

interface IProps {

}

const quotes = [
  `Whatever joy there is in this world all comes from desiring others to be happy, and whatever suffering there is in this world all comes from desiring myself to be happy - Shantideva`,
  `Interdependence`,
  `Secular Buddhism`,
  `The immeasurables are equanimity, love, compassion and joy`,
  `To understand that everyday is a good day is enlightenment`
]

const rnd = Math.floor(Math.random() * quotes.length);
const quote = quotes[rnd];

class Quote extends React.Component<IProps> {
  public render() {
    return (
      <div className="Quote">
        {quote}
      </div>
    );
  }
}

export default Quote;