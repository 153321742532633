import React from 'react';
import classnames from 'classnames';
import { EventStore, useEventStore } from '../../../stores/eventStore';
import { computed } from 'mobx';
import Icon from '../../common/Icon';
import { observer } from 'mobx-react';
import moment from 'moment';
import DateStore, { useDateStore } from '../../../stores/dateStore';

interface IProps {
  onClick: () => void
  weekdays: string[]
  title: string
  name: string
}

@observer
class Habit extends React.Component<IProps> {
  private eventStore: EventStore = useEventStore();
  private dateStore: DateStore = useDateStore();

  @computed
  get isDone(): boolean {
    return !!this.eventStore.getEvents('habit', this.props.name).length
  }

  @computed
  get isDue(): boolean {
    if (this.props.weekdays.length <= 0) {
      return true;
    }
    
    const weekday = moment.weekdays(moment(this.dateStore.activeDate).weekday())
    return this.props.weekdays.indexOf(weekday) !== -1;
  }

  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={classnames('Habitbox', { 'done': this.isDone, 'due': this.isDue })}
      >
        {
          this.isDone ?
          <Icon id="Checkmark" height={20} /> :
          <Icon id="Hourglass" height={20} />
        }
        <span className="Habitbox__title">{this.props.title}</span>
      </div>
    )
  }
}

export default Habit