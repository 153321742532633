import React from 'react';
import './Settings.css'
import { observer } from 'mobx-react';
import { useUserStore } from '../../stores/userStore';

export interface IProps {

}

@observer
class Settings extends React.Component<IProps> {

  public render() {
    return (
      <div className="Settings">
        <div>
          <button onClick={this.clearCache}>Clear Cache</button>
        </div>
        <div>
          <button onClick={this.signOut}>Sign out</button>
        </div>
        <div className="Info">
          <pre>
{`
private habits = [
  {
    title: 'Drugs',
    name: 'drugs',
    isDone: false,
    isDue: true
  },
  {
    title: 'Daily Planning',
    name: 'planning',
    isDone: false,
    isDue: true
  },
  {
    title: 'FFT',
    name: 'fft',
    isDone: false,
    isDue: isDow('Wednesday')
  },
  {
    title: 'Exercise',
    name: 'exercise',
    isDone: false,
    isDue: isDow('Monday') || isDow('Wednesday') || isDow('Saturday')
  },
  {
    title: 'Meditation',
    name: 'meditation',
    isDone: false,
    isDue: true
  }
]
`}
          </pre>
        </div>
      </div>
    );
  }

  private signOut = () => {
    useUserStore().signOut();
  }

  private clearCache = () => {
    localStorage.removeItem('statStore')
    window.location.reload()
  }
}

export default Settings;
