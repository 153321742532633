import React from 'react';
import Router from '../Router';
import UserStore, { useUserStore } from '../../stores/userStore';
import { observer } from 'mobx-react';
import './App.css'

@observer
class App extends React.Component<any> {
  private userStore: UserStore = useUserStore();

  public componentDidMount() {
    this.userStore.addReactions();
  }

  public componentWillUnmount() {
    this.userStore.removeReactions();
  }

  private signIn() {
    this.userStore.signIn();
  }

  public render() {
    const { isLoading, isAuthenticated } = this.userStore

    return (
      <div className="App">
        {
          isLoading ? <span>Loading...</span> : (
              isAuthenticated ?
                <Router /> :
                <button className="signIn" onClick={this.signIn.bind(this)}>Sign In</button>
          )
        }
      </div>
    );
  }
}

export default App;