
const firebaseConfig = {
  apiKey: "AIzaSyAgRynONuVPUJJ0k9Bgt8VObu-6B_TchiU",
  authDomain: "habitboy-4fbe6.firebaseapp.com",
  databaseURL: "https://habitboy-4fbe6.firebaseio.com",
  projectId: "habitboy-4fbe6",
  storageBucket: "habitboy-4fbe6.appspot.com",
  messagingSenderId: "282121399844",
  appId: "1:282121399844:web:e42618a8244c9f5b"
};

export default firebaseConfig;
