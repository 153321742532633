import React from 'react';
import './Goal.css'
import { observer } from 'mobx-react';
import { observable, action, reaction, IReactionDisposer, toJS } from 'mobx';
import { useGoalStore, GoalStore, GoalEntity } from '../../../stores/goalStore';
import { RouteComponentProps } from "react-router-dom";
import { format } from '../../../utils/dates';

export interface Params {
  goalId: string
}

export interface IProps extends RouteComponentProps<Params> {

}

export interface IState {
}

@observer
class Goal extends React.Component<IProps> {
  private goalStore: GoalStore = useGoalStore();

  private dispose: IReactionDisposer | undefined;

  @observable
  private goal?: GoalEntity;

  @observable
  private showAdvanced: boolean = false

  public componentDidMount() {
    this.goalStore.addReactions();

    if (this.props.match.params.goalId) {
      this.dispose = reaction(
        () => this.goalStore.goals,
        () => {
          const goalId = this.props.match.params.goalId;

          const goal = this.goalStore.goals.find(goal => goal.id === goalId);
          if (!goal) {
            return;
          }

          // make a cloned object
          this.goal = toJS(goal);
        }
      )
    } else {
      this.setDefaultValues();
    }

  }
  
  public componentWillUnmount() {
    this.goalStore.removeReactions();
    (this.dispose && this.dispose());
  }

  public handleCancelButtonClick() {
    this.props.history.push("/goals");
  }

  private setDefaultValues() {
    this.goal = {
      title: '',
      motivation: '',
      tasks: [],
      progress: '',
      deadlineAt: format(new Date((new Date()).valueOf() + 86400000 * 7)),
      description: '',
      createdAt: format(new Date()),
      completedAt: null,
      isArchived: false
    };
  }

  @action
  public handleDeleteClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await this.goalStore.deleteGoal(this.goal?.id as string);
    this.handleCancelButtonClick();
  }

  @action
  public handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.handleGoalChange(e.target.name, e.target.value);
  }

  @action
  public handleGoalChange = (propertyKey: string, value: any) => {
    (this.goal as any)[propertyKey] = value;
  }

  @action
  public async handleSaveClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (this.goal && this.goal.id) {
      await this.goalStore.updateGoal(this.goal?.id as string, this.goal);
    } else {
      await this.goalStore.createGoal(this.goal);
    }
    this.handleCancelButtonClick();
  }

  @action
  public handleShowAdvancedToggle(e: React.MouseEvent<HTMLButtonElement>) {
    this.showAdvanced = !this.showAdvanced;
  }

  public render() {
    if (!this.goal) {
      return <div>Loading...</div>
    }

    return (
      <div className="GoalPage">
        <div>
          <input type="text" placeholder="Title..." name="title" value={this.goal.title} onChange={this.handleInputChange.bind(this)} />
          <textarea name="description" placeholder="Description..." value={this.goal.description} onChange={this.handleInputChange.bind(this)} />
          <textarea name="motivation" placeholder="Motivation..." value={this.goal.motivation} onChange={this.handleInputChange.bind(this)} />
          <input type="text" placeholder="Progress..." name="progress" value={this.goal.progress} onChange={this.handleInputChange.bind(this)} />
          <input type="date" name="deadlineAt" value={this.goal.deadlineAt} onChange={this.handleInputChange.bind(this)} />
        </div>

        <div>
          <button className="saveBtn" onClick={this.handleSaveClick.bind(this)}>Save and close</button>
          <button className="cancelBtn" onClick={this.handleCancelButtonClick.bind(this)}>Cancel</button>
        </div>

        <div className="advanced">
          <button onClick={this.handleDeleteClick.bind(this)}>Delete</button>
          <button onClick={this.handleShowAdvancedToggle.bind(this)}>
            {this.showAdvanced ? 'Hide advanced details' : 'Show advanced details'}
          </button>
          {this.showAdvanced && <pre>{JSON.stringify(this.goal, null, 2)}</pre>}
        </div>

      </div>
    );
  }
}

export default Goal;


