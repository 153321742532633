import { observable, runInAction, action, reaction } from "mobx";
import { useFirebase } from "../services/FirebaseService";
import { useUserStore } from "./userStore";
import { format, getDefaultDateFormat } from "../utils/dates";
import { filter } from "../utils/lists";
import moment from 'moment';
import { useDateStore } from "./dateStore";

export interface ICreateEventDTO {
  type: string;
  name: string;
}

export class EventStore {
  @observable
  public events: any

  @observable
  public weeklyEvents: any

  private dateStore = useDateStore();

  private reactions: (() => void)[] = [];

  private firebaseRefs: firebase.database.Reference[] = [];

  private eventRef: firebase.database.Reference | null = null;

  @action
  public upsertEvent(event: ICreateEventDTO) {
    const existing = this.getEvents(event.type, event.name);
    if (existing.length) {
      existing.forEach((event) => this.removeEvent(event.id));
    }

    this.createEvent(event);
  }

  @action
  public createEvent(event: any) {
    if (this.eventRef === null) {
      throw new Error("this.eventRef is null")
    }

    const id = this.eventRef.push().key as string;

    event['id'] = id;
    event['time'] = format(this.dateStore.activeDate);

    this.eventRef.child(id).set(event);
  }

  public removeEvent(eventId: string) {
    if (this.eventRef === null) {
      throw new Error("this.eventRef is null")
    }

    this.eventRef.child(eventId).remove();
  }

  public getEvents(type: string, name: string): any[] {
    return filter(this.events, (event: any) => 
      event.type === type &&
      event.name === name
    );
  }

  private setupWeeklyEvents() {
    const database = useFirebase().database();
    const uid = useUserStore().userId;
    const ref = database.ref(`users/${uid}/events`)

    this.firebaseRefs.push(ref);

    const today = moment(this.dateStore.activeDate);
    const weekStart = today.startOf('isoWeek').format(getDefaultDateFormat());
    const weekEnd = today.endOf('isoWeek').format(getDefaultDateFormat());

    const query = ref.orderByChild('time').startAt(`${weekStart}`).endAt(`${weekEnd} 23:59:59`);

    query.on('value', (snapshot) => {
      runInAction(() => {
        const value = snapshot.val();
        this.weeklyEvents = value || [];
      });
    });

    query.on('child_removed', (snapshot) => {
      const child = snapshot.val();

      this.weeklyEvents = filter(this.weeklyEvents, (event: any) => event.id !== child.id);
    });
  }

  private setupDailyEvents() {
    const database = useFirebase().database();
    const uid = useUserStore().userId;
    const ref = this.eventRef = database.ref(`users/${uid}/events`)

    this.firebaseRefs.push(ref);

    const today = format(this.dateStore.activeDate);
    const query = ref.orderByChild('time').startAt(today).endAt(`${today} 23:59:59`);

    query.on('value', (snapshot) => {
      runInAction(() => {
        const value = snapshot.val();
        this.events = !!value ? value : [];
      });
    });

    query.on('child_removed', (snapshot) => {
      const child = snapshot.val();

      this.events = filter(this.events, (event: any) => event.id !== child.id);
    });
  }

  public addReactions() {
    this.reactions.push(reaction(
      () => this.dateStore.activeDate,
      () => {
        // reset firebase refs with updated date settings
        this.disposeFirebaseRefs();
        this.setupFirebaseRefs();
      }
    ))

    // initialize firebase refs
    this.setupFirebaseRefs();
  }

  public removeReactions() {
    this.reactions && this.reactions.forEach(dispose => dispose());
    this.disposeFirebaseRefs();
  }

  private setupFirebaseRefs() {
    this.setupDailyEvents();
    this.setupWeeklyEvents();
  }

  private disposeFirebaseRefs() {
    this.firebaseRefs && this.firebaseRefs.forEach(ref => ref.off());
  }
}

let eventStore: EventStore;
export const useEventStore = () => {
  if (!eventStore) {
    eventStore = new EventStore();
  }
  
  return eventStore;
}
