import React from 'react'
import './Pomodoro.css'
import { observer } from 'mobx-react';
import { observable, action, runInAction, computed } from 'mobx';
import { EventStore, useEventStore, ICreateEventDTO } from '../../../stores/eventStore';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { filter } from '../../../utils/lists';

const tryParseInt = (str: any, defaultValue: number | string) => {
  var retValue = defaultValue;
  if(str !== null) {
    if(str.length > 0) {
      if (!isNaN(str)) {
          retValue = parseInt(str);
      }
    }
  }
  return retValue;
}

interface IProps extends RouteComponentProps {
}

export interface IPomodoroEvent extends ICreateEventDTO {
  value: number;
}

@observer
class Pomodoro extends React.Component<IProps> {
  @observable
  private pomodoros: string | number = '';

  @observable
  private error: string = '';

  private eventStore: EventStore = useEventStore();

  public componentDidMount() {
    this.eventStore.addReactions();
  }

  public componentWillUnmount() {
    this.eventStore.removeReactions();
  }

  @computed
  private get events() {
    return filter(this.eventStore.events, (event: any) => {
      return event.type === 'tracker';
    });
  }

  public render() {
    return (
      <div className="Pomodoro">
        <h1>Pomodoro</h1>

        <div className="Pomodoro__section">
          <label>How many?</label>
          <input onChange={this.changeHandler} value={this.pomodoros} type="number" />
        </div>

        <div className="Pomodoro__section">
          <label>What type?</label>
          <button onClick={this.submitHandler.bind(this, 'work')}>Work</button>
          <button onClick={this.submitHandler.bind(this, 'saw')}>Sharpen the Saw</button>
          <button onClick={this.submitHandler.bind(this, 'project')}>Project</button>
        </div>

        <p className="Pomodoro__error">{this.error}</p>

        <pre>{JSON.stringify(this.events, null, 2)}</pre>
      </div>
    );
  }

  @action
  private changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.pomodoros = tryParseInt(e.target.value, '');
  };

  @action
  private submitHandler(subcategory: string) {
    if (this.pomodoros === '') {
      console.log(this.pomodoros)
      this.error = 'Please enter amount of pomodoros to add (or 0 to remove one)'
      setTimeout(() => {
        runInAction(() => {
          this.error = ''
        });
      }, 5000);
      return;
    }

    if (this.pomodoros === 0) {
      const events = this.eventStore.getEvents('tracker', `pomodoro.${subcategory}`);
      events.forEach(pomodoro => {
        this.eventStore.removeEvent(pomodoro.id)
      });
      return;
    }

    this.eventStore.upsertEvent({
      type: 'tracker',
      name: `pomodoro.${subcategory}`,
      value: this.pomodoros
    } as IPomodoroEvent);
  };
}

export default withRouter(Pomodoro)
