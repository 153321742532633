import React from 'react';
import './ProgressBar.css'
import { upperCaseFirst } from '../../../utils/str';

interface IProps {
  label: string
  now: number
  total: number
}

class ProgressBar extends React.Component<IProps> {
  public render() {
    const { label, now, total } = this.props;
    let ratio = now / total;

    if (ratio > 1) {
      ratio = 1;
    }

    const width = ratio * 100;
    const modifier = ratio > 0.5 ? 'green' : 'blue';

    return (
      <div className="Progress">
        <span className="Progress__label">{upperCaseFirst(label)}: {now} / {total}</span>
        <div className={`ProgressBar ProgressBar--${modifier}`} style={{width: `${width}%`}}>
        </div>
      </div>
    );
  }
}

export default ProgressBar;
