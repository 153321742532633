import React from 'react'
import './Food.css'
import { runInAction, computed } from 'mobx';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { EventStore, useEventStore, ICreateEventDTO } from '../../../stores/eventStore';
import { filter } from '../../../utils/lists';

interface IProps {

}

const defaultCalories = 2200;

const consumables = [
  {
    type: 'food',
    name: 'coffee',
    title: 'Coffee',
    value: 0
  },
  {
    type: 'food',
    name: 'meal.greens',
    title: 'Meal (50% greens)',
    value: 900
  },
  {
    type: 'food',
    name: 'meal.nogreens',
    title: 'Meal (no greens)',
    value: 900
  },
  {
    type: 'food',
    name: 'meal.small',
    title: 'Meal (small)',
    value: 400
  },
  {
    type: 'food',
    name: 'snack.small',
    title: 'Snack (hole)',
    value: 250
  },
  {
    type: 'food',
    name: 'snack.large',
    title: 'Snack (palm+)',
    value: 500
  },
  {
    type: 'food',
    name: 'softdrink.sugar',
    title: 'Coke (with sugar)',
    value: 140
  },
  {
    type: 'food',
    name: 'softdrink.nosugar',
    title: 'Coke (without sugar)',
    value: 0
  },
  {
    type: 'food',
    name: 'alcohol.1unit',
    title: 'Alcohol (1 unit)',
    value: 140
  }
]

const makeTitle = (name: string) => {
  const consumable = consumables.find(item => item.name === name);
  if (!consumable) {
    return `N/A (${name})`;
  }
  return consumable.title;
}

export interface IFoodEvent extends ICreateEventDTO {
  id?: string;
  value: number;
}

/*
Happiness, energi and health

2200 kcal

- meal (50% greens) | 1000 kcal = 0.45
- meal (no greens) | 1000 kcal = 0.45
- meal (small) | 500 kcal = 0.22
- coffee |  0 kcal = 0
- snack (hole) | 250 kcal = 
- snack (fist+) | 500 kcal
- coke (with sugar) |  140 kcal
- coke (without sugar) | 0 kcal
- alcohol | 140 kcal



*/
@observer
class Food extends React.Component<IProps> {
  @computed
  private get counter(): number {
    let counter = defaultCalories;
    this.events.forEach((event: IFoodEvent) => {
      counter -= event.value;
    });
    return counter;
  }

  private eventStore: EventStore = useEventStore();

  @computed
  private get events(): IFoodEvent[] {
    return filter(this.eventStore.events, (event: any) => {
      return event.type === 'food';
    });
  }

  public componentDidMount() {
    this.eventStore.addReactions();
  }

  public componentWillUnmount() {
    this.eventStore.removeReactions();
  }

  public handleButtonClick (consumable: any) {
    runInAction(() => {
      this.eventStore.createEvent({
        type: 'food',
        name: consumable.name,
        value: consumable.value
      } as IFoodEvent);
    });
  }

  public removeFood(event: IFoodEvent) {
    if (!event.id) {
      throw new Error();
    }

    this.eventStore.removeEvent(event.id);
  }

  public render() {
    const counterClasses = classNames({
      'Food__counter': true,
      'Food__counter--negative': this.counter < 0
    });

    return (
      <div className="Food">
        <div className="Food__header">
          <p>It controls your happiness, health and energi.</p>
          <h1>Food</h1>
        </div>
        <div className="Food__section">
          <div className={counterClasses}>
            {this.counter}
          </div>
        </div>
        <div className="Food__section">
          {consumables.map((consumable, index) => (
            <button onClick={this.handleButtonClick.bind(this, consumable)} key={index}>
              <div className="value">{consumable.value}</div>
              <div className="title">{consumable.title}</div>
            </button>
          ))}
        </div>
        <hr />

        <div className="events">
          {this.events.map((event) => (
            <div key={event.id} className="event" onClick={this.removeFood.bind(this, event)}>
              <div className="value">{event.value}</div>
              <div className="title">{makeTitle(event.name)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Food

