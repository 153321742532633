import firebase from 'firebase/app';
import firebaseConfig from "../constants/firebaseConfig";
import 'firebase/auth';
import 'firebase/database'

let instance: firebase.app.App
export const useFirebase = () => {
  if (!instance) {
    instance = firebase.initializeApp(firebaseConfig);
  }

  return instance;
}