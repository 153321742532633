import React from 'react'
import moment from 'moment';
import { format } from '../../utils/dates';
import './Analytics.css'
import { useFirebase } from '../../services/FirebaseService';
import { useUserStore } from '../../stores/userStore';
import { runInAction, action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { map, forEach } from '../../utils/lists';
import classNames from 'classnames';

interface IDataPointProps {
  events: any,
  type: any
}

const DataPoint: React.SFC<IDataPointProps> = ({ events, type }) => {
  let counter = 0;

  forEach(events, (event: any) => {
    if (event.name === type)
      counter += event.value;

    if (type === 'coffee' && event.name === 'coffee') {
      counter++;
    }
  })

  const classes = counter ? "success" : "";

  return (
    <span className={classes}>{counter}</span>
  );
};

interface IProps {

}

@observer
class Analytics extends React.Component<IProps> {
  public period = {
    start: moment().add(-30, 'days').toDate(),
    end: new Date()
  };

  public days: any;

  @observable
  public dataPointType = 'pomodoro.work';

  private firebaseRefs: firebase.database.Reference[] = [];

  public componentWillMount() {
    this.query();
  }

  private query(): Promise<any> {
    return new Promise((resolve, reject) => {
      const database = useFirebase().database();
      const uid = useUserStore().userId;
      const ref = database.ref(`users/${uid}/events`)

      this.firebaseRefs.push(ref);

      const start = format(this.period.start);
      const end = format(this.period.end);

      const query = ref.orderByChild('time').startAt(`${start}`).endAt(`${end} 23:59:59`);

      query.once('value', (snapshot) => {
        runInAction(() => {
          const value = snapshot.val();

          if (!value)
            return;

          console.log(value);

          // this.weeklyEvents = value;
          this.days = this.createDays();
          this.analyze(value);

          resolve(value);

          this.forceUpdate();
        });
      });
    })
  }

  public componentWillUnmount() {
    this.firebaseRefs.forEach(ref => ref.off());
  }

  private analyze(data: any) {
    forEach(data, (event: any) => {
      this.days[event.time].push(event);
    });
  }

  private createDays(): any {
    let days: any = {};
    let pointer = this.period.start;
    const endTime = this.period.end.getTime();

    while (pointer.getTime() <= endTime) {
      const date = format(pointer);
      days[date] = [];
      pointer = moment(pointer).add(1, 'day').toDate();
    }

    return days;
  }

  public async downloadAsCSV() {
    const events = await this.query();

    const rows = map(events, (event: any) => {
      return [
        `'${event.id}`,
        event.time,
        event.type,
        event.name,
        event.value
      ];
    });

    let csvContent = rows.map(e => e.join(";")).join("\n");
    
    var pom = document.createElement('a');
    var blob = new Blob([csvContent],{type: 'text/csv;charset=utf-8;'});
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', 'habitboy.csv');
    pom.click();
  }

  @action
  public setDataPointType(type: string) {
    this.dataPointType = type;
    this.forceUpdate();
  }

  public render() {
    const days = this.days;

    const dataPointTypes = ['exercise', 'pomodoro.work', 'pomodoro.project', 'pomodoro.saw', 'coffee', 'drugs'];

    return (
      <div className="analytics">
        <div className="period">
          {format(this.period.start)} - {format(this.period.end)}
        </div>
        <div className="dataPointBtnList">
          {dataPointTypes.map(type => (
            <button key={type} className={classNames('DataPointBtn', {'active': type === this.dataPointType})} onClick={this.setDataPointType.bind(this, type)}>{type}</button>
          ))}
        </div>
        <div>dataPointType: {this.dataPointType}</div>
        <button onClick={this.downloadAsCSV.bind(this)}>Download as CSV</button>
        <div className="dates">
          {map(days, (events: any, date: string) => (
            <div key={date}>
              <div className="date">
                {date}: <DataPoint type={this.dataPointType} events={events} /> - {moment.weekdays()[moment(date).weekday()]}
              </div>
              {moment(date).weekday() === 0 && <hr />}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Analytics
