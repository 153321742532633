import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Dashboard from "../Dashboard";
import './Router.css'
import Icon from "../../components/common/Icon";
import Settings from "../Settings";
import Pomodoro from "../Trackers/Pomodoro";
import Food from "../Trackers/Food";
import Analytics from "../Analytics";
import { useUIStore } from "../../stores/uiStore";
import { observer } from "mobx-react";
import Goals from "../Goals";
import Goal from "../Goals/Goal";

interface IProps {
}

const RouterWrapper: React.FC<IProps> = observer(() => {
  const uiStore = useUIStore();

  return (
    <Router>
      <div className="Router">
        <div className="Router__header">
          <button onClick={uiStore.toggleMenu.bind(uiStore)}>
            <Icon title="Menu" id="Burger"/>
          </button>
          <Link to="/">Home</Link>
        </div>
        {uiStore.isMenuActive && (
          <div className="Router__menu" onClick={uiStore.toggleMenu.bind(uiStore)}>
            <Link to="/" className="link">Habitboy</Link>
            <Link to="/goals" className="link">Goals</Link>
            <Link to="/analytics" className="link">Analytics</Link>
            <Link to="/settings" className="link">Settings</Link>
          </div>
        )}
        <div className="Router__body">
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/goals" component={Goals} />
          <Route path="/goals/goal/:goalId" component={Goal} />
          <Route exact path="/goals/goal" component={Goal} />
          <Route exact path="/trackers/pomodoro" component={Pomodoro} />
          <Route exact path="/trackers/food" component={Food} />
          <Route exact path="/analytics" component={Analytics} />
        </div>
      </div>
    </Router>
  );
})

export default RouterWrapper;