import { observable, runInAction, action } from 'mobx';
import { useFirebase } from '../services/FirebaseService';
import firebase from 'firebase/app';

class UserStore {
  @observable
  public isAuthenticated: boolean = false;

  @observable
  public isLoading: boolean = true;

  private reactions: (() => void)[] = [];

  @observable
  public user: firebase.User | null = null;

  public addReactions() {
    this.reactions.push(useFirebase().auth().onAuthStateChanged((user: firebase.User | null) => {
      runInAction(() => {
        this.user = user;
        this.isAuthenticated = !!user;

        if (this.isLoading)
          this.isLoading = false;
      });
    }));
  }

  get userId(): string | null {
    if (!!this.user) {
      return this.user.uid;
    }

    return null;
  }

  public removeReactions() {
    this.reactions.length && this.reactions.forEach(dispose => dispose());
  }

  public signIn() {
    firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
  }

  @action
  public signOut() {
    useFirebase().auth().signOut();
  }
}

let userStore: UserStore;
export const useUserStore = (): UserStore => {
  if (!userStore) {
    userStore = new UserStore();
  }
  
  return userStore;
};

export default UserStore;
