import React from 'react';
import './Tasks.css'
import { observable } from 'mobx'
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { TaskStore, useTaskStore } from '../../stores/taskStore';

export interface IProps {

}

@observer
class Tasks extends React.Component<IProps> {
  @observable isExpanded = false

  private taskStore: TaskStore = useTaskStore()

  public renderExpanded() {
    let updateProperty = (event: any, task: any) => {
      this.taskStore.updateTask(task.id, {
        title: event.target.value
      })
    }
    let tryMoveToIndex = (previousIndex: number) => {
        let previousItems = document.querySelectorAll("textarea[data-index='" + previousIndex + "']")
        if (previousItems.length === 1) {
          let item = previousItems[0] as HTMLTextAreaElement
          item.focus();
          item.setSelectionRange(item.value.length,item.value.length,"forward");
          return true
        } else {
          return false
        }
    }
    let onKeyPress = (event: any) => {
      let currentIndex = parseInt(event.target.getAttribute("data-index"))

      // Toggle completion
      if (event.key === 'Enter' && event.ctrlKey) {
        event.nativeEvent.preventDefault();
        let task = this.taskStore.tasks[currentIndex];
        this.taskStore.updateTask(task.id as string, { isComplete: !task.isComplete })
      }

      // Move down
      if (event.key === 'Enter' && !event.ctrlKey) {
        event.nativeEvent.preventDefault()
        let nextIndex = currentIndex + 1
        if (!tryMoveToIndex(nextIndex)) {
          this.taskStore.createTask({
            title: ''
          })
          setTimeout(() => {
            tryMoveToIndex(nextIndex)
          }, 50)
        }
      }

      // Move up
      if (event.key === 'Backspace' && event.target.value.length === 0) {
        let previousIndex = currentIndex - 1
        if (tryMoveToIndex(previousIndex)) {
          event.nativeEvent.preventDefault()
          this.taskStore.deleteTask(this.taskStore.tasks[currentIndex].id as string) // remove task
        }
      }

      if (event.key === 'ArrowUp') {
        tryMoveToIndex(currentIndex - 1)
      }

      if (event.key === 'ArrowDown') {
        tryMoveToIndex(currentIndex + 1)
      }
    }

    return (
      <div className="Tasks__expanded">
        <h1>Tasks <button onClick={() => this.isExpanded = false}>Close</button></h1>
        {this.taskStore.tasks.map((task, index) => (
          <div key={task.id} className="Task">
            <textarea
              className={classNames('Task__title', {'Task--complete': task.isComplete})}
              data-index={index}
              onKeyDown={onKeyPress}
              onChange={(event) => updateProperty(event, task)}
              value={task.title} />
          </div>
        ))}
        <div className="Tasks__utils">
          <button onClick={() => this.taskStore.createTask({ title: '' })}>Create task</button>
          { this.taskStore.isLoading && ('Loading...') }
          <pre>
            {JSON.stringify(this.taskStore.tasks, null, 2)}
          </pre>
        </div>
      </div>
    )
  }

  public render() {
    if (this.isExpanded) {
      return this.renderExpanded();
    }

    const totalTasks = this.taskStore.tasks.length
    const completedTasks = this.taskStore.tasks.filter(t => t.isComplete).length
    const showNudge = totalTasks === 0 || (totalTasks === 1 && this.taskStore.tasks[0].title.length === 0)
    const celebrate = totalTasks > 0 && totalTasks === completedTasks
    return (
      <div className="Tasks">
        <button onClick={() => this.isExpanded = true}>Expand</button>
        <h1>Remaining Tasks</h1>
        {showNudge && (
          <div>Let's get started plannig this day! :)</div>
        )}
        {celebrate && (
          <div>You have completed all your tasks</div>
        )}
        <div>
          {this.taskStore.tasks.filter(t => !t.isComplete).map((task, index) => (
            <div key={task.id} className={classNames('TaskSummary', {'TaskSummary--complete': task.isComplete})}>
              {task.title}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default Tasks;

