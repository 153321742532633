import React from 'react';
import './Goals.css'
import { observer } from 'mobx-react';
import { format } from '../../utils/dates';
import { action } from 'mobx';
import { GoalStore, useGoalStore, GoalEntity } from '../../stores/goalStore';
import { Link } from 'react-router-dom';

export interface IProps {

}

export interface IState {
}

@observer
class Goals extends React.Component<IProps, IState> {
  private goalStore: GoalStore = useGoalStore();

  public componentDidMount() {
    this.goalStore.addReactions();
  }
  
  public componentWillUnmount() {
    this.goalStore.removeReactions();
  }

  @action
  private async handleMarkAsDone (goal: GoalEntity, value: Date | null) {
    goal.completedAt = value;

    await this.goalStore.updateGoal(goal.id as string, {
      completedAt: value
    });
  }

  @action
  private async handleArchiveClick (goal: GoalEntity) {
    goal.isArchived = true;

    await this.goalStore.updateGoal(goal.id as string, {
      isArchived: true
    });
  }

  public render() {
    const goals = this.goalStore.goals;

    return (
      <div className="Goals">
        <div className="Goals__header">
          <h1>Goals</h1>
          <div className="Goals__actions">
            <Link to={'/goals/goal'}>Create Goal</Link>
          </div>
        </div>
        {goals.map((goal: GoalEntity, key) => (
          <div className="Goal" key={key}>
            <div className="Goal__top">
              {goal.completedAt && <span className="Goal__completed">✓</span>}
              <Link to={`/goals/goal/${goal.id}`} className="Goal__title">{goal.title}</Link>
            </div>
            <div className="Goal__bottom">
              <div className="Goal__progress">
                <span>{goal.progress}</span>
              </div>
              <div className="Goal__deadline">
                <span>{format(new Date(goal.deadlineAt), 'D. MMM (w)')}</span>
              </div>
              <div className="Goal__actions">
                {!goal.completedAt && <button className="Goal__done" onClick={this.handleMarkAsDone.bind(this, goal, new Date())}>Mark as done</button>}
                {goal.completedAt && <button className="Goal__done" onClick={this.handleMarkAsDone.bind(this, goal, null)}>Unmark as done</button>}
                <button className="Goal__archive" onClick={this.handleArchiveClick.bind(this, goal)}>Archive</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default Goals;

